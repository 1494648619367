import { useTranslation } from "react-i18next";
import { REGION } from "@src/constant";
import { Image } from "@components/common";
import Link from "next/link";
// import { useContext } from "react";

export default function Footer() {
  const { t } = useTranslation();

  // const openContactDialog = useContext(SetContactDialogContext);

  const footerNavigation = {
    support: [{ name: t("home.footer.labels.pricing"), href: "#pricing" }],

    legal: [
      // { name: t("home.footer.labels.claim"), href: "#" },
      {
        name: t("home.footer.labels.service.cloud"),
        href: "/agreement/service",
      },
      {
        name: t("home.footer.labels.service.disk"),
        href: "/agreement/disk",
      },
      {
        name: t("home.footer.labels.service.hosting"),
        href: "/agreement/hosting",
      },
      { name: t("home.footer.labels.privacy"), href: "/agreement/privacy" },
    ],
  };
  return (
    <footer className="bg-gray-100" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        {t("home.footer.name")}
      </h2>
      <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
        <div>
          <div className="gap-8">
            {/* <div className="md:grid md:grid-cols-2 md:gap-8"> */}
            {/* <div>
                <h3 className="text-base font-medium text-gray-900">
                  {t("home.footer.labels.solutions")}
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.solutions.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div> */}
            {/* <div className="mt-12 md:mt-0">
                <h3 className="text-base font-medium text-gray-900">
                  {t("home.footer.labels.support")}
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.support.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                  <li>
                    <span
                      className="text-base text-gray-500 hover:text-gray-900 cursor-pointer"
                      onClick={openContactDialog}
                    >
                      {t("home.footer.labels.contact")}
                    </span>
                  </li>
                </ul>
              </div> */}
            {/* </div> */}
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div className="mt-12 md:mt-0">
                <ul className="mt-4 space-x-4">
                  {footerNavigation.legal.map((item) => (
                    <Link
                      key={item.name}
                      href={item.href}
                      className="text-base text-gray-900"
                    >
                      {item.name}
                    </Link>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* <div className="mt-12 xl:mt-0">
            <h3 className="text-base font-medium text-gray-900">
              {t("subscribeTitle")}
            </h3>
            <p className="mt-4 text-base text-gray-500">
              {t("subscribeSubtitle")}
            </p>
            <form className="mt-4 sm:flex sm:max-w-md">
              <label htmlFor="email-address" className="sr-only">
                {t("email")}
              </label>
              <input
                type="email"
                name="email-address"
                id="email-address"
                autoComplete="email"
                required
                className="w-full min-w-0 appearance-none rounded-md border border-gray-300 bg-white py-2 px-4 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-primary focus:placeholder-gray-400 focus:outline-none focus:ring-primary"
                placeholder={t("enterEmail") as string}
              />
              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  type="submit"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-cyan-600 to-primary bg-origin-border px-4 py-3 text-base font-medium text-white shadow-sm hover:from-primary hover:to-primary"
                >
                  {t("subscribe")}
                </button>
              </div>
            </form>
          </div> */}
        </div>
        <div className="mt-6 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
          <p className="mt-8 text-base text-gray-400 md:order-1 md:mt-0">
            &copy;
            {` ${new Date().getFullYear()} 上海武健算网 版权所有`}
          </p>
        </div>
        <div className="flex mt-4 text-base text-gray-400 flex-wrap">
          <span rel="noreferrer">沪ICP备2024085720号-1</span>
        </div>
      </div>
    </footer>
  );
}
