import { HiMiniCheckCircle } from "react-icons/hi2";

export interface FeatureCardProps {
  title: string;
  features: string[];
  button: React.ReactNode;
}

export const btnClass =
  "flex w-full justify-center mx-auto h-12 rounded-lg items-center mt-8 bg-black text-white cursor-pointer";

export default function Card({ title, features, button }: FeatureCardProps) {
  return (
    <div className="rounded-3xl px-8 py-6 group hover:bg-primary transition-colors duration-300 flex flex-col justify-between hover:shadow">
      <div>
        <div className="text-2xl mb-8">{title}</div>
        {features.map((feature, index) => (
          <div key={index} className="flex items-start mb-4">
            <HiMiniCheckCircle className="inline-block h-5 w-5 mt-0.5 mr-1" />
            <span className="flex-1">{feature}</span>
          </div>
        ))}
      </div>

      {button}
    </div>
  );
}
