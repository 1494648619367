import { JumpIn } from "@components/common/Animation/JumpIn";
import { Image } from "@components/common";
import { useTranslation, Trans } from "react-i18next";

export default function Hero() {
  const { t } = useTranslation();
  // const hostname = window.location.hostname;

  return (
    <div className="my-6">
      {/* Hero section */}
      <div className="relative">
        <div className="absolute inset-x-0 bottom-0 h-full" />
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="relative sm:overflow-hidden sm:rounded-2xl">
            <div className="absolute inset-0">
              <Image
                className="h-full w-full object-cover"
                src="/indexBg.png"
                alt="People working on laptops"
              />
              <div className="absolute inset-0 bg-gradient-to-r mix-blend-multiply" />
            </div>
            <div className="relative h-[328px]"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
